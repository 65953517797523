// config.js


const config = {
  projectId: 'tango-30',
  domain: 'https://30.tangoai.co',
  
  sidebarPages: {
    admin: ['Dashboard', 'Reports', 'Playground', 'Optimization'],
    user: ['Dashboard', 'Playground']
  },
  
  exemptedColumns: [
    'Invoice_Number', 
    'Customer_UID', 
    'invoice date', 
    'shipping date', 
    'year', 
    'YEAR', 
    'Year', 
    'Yealry%', 
    'Month', 
    'MONTH'
  ]
};

export default config;